/* eslint-disable sort-keys-fix/sort-keys-fix */
"using client";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { ReactNode } from "react";
import { Heading } from "../base/heading";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Text } from "./text";
import "@splidejs/react-splide/css";
import { ArrowRightIcon } from "../icons/icons";
import { classNames } from "@/utils/styling-utils";

interface SwiperProps {
  fullWidth?: boolean;
  heading?: string;
  isSidebar?: boolean;
  items: ReactNode[];

  /**
   * itemsPerPageMedium gjelder for medium skjermstørrelse (600px-768px)
   */
  itemsPerPageMedium?: number;

  /**
   * itemsPerPageLarge gjelder for large skjermstørrelse (768px og større)
   */
  itemsPerPageLarge?: number;

  /**
   * itemsPerPageXLarge gjelder for large skjermstørrelse (1024px og større)
   */
  itemsPerPageXLarge?: number;
  notInfinite?: boolean;
  showArrowsAlways?: boolean;
  showArrowsOnTop?: boolean;
  text?: string;
}

export const Swiper = ({
  fullWidth,
  heading,
  isSidebar,
  items,
  itemsPerPageMedium,
  itemsPerPageLarge,
  itemsPerPageXLarge,
  notInfinite,
  showArrowsAlways,
  showArrowsOnTop,
  text
}: SwiperProps) => {
  const defaultNumberOfItems = 3;
  const itemsPerPageSmallScreen = 1;
  const itemsPerPageMediumScreen = itemsPerPageMedium ?? 2;
  const itemsPerPageLargeScreen = isSidebar ? 1 : (itemsPerPageLarge ?? defaultNumberOfItems); // sidebar blir først synlig på stor skjerm
  const itemsPerPageXLargeScreen = isSidebar ? 1 : (itemsPerPageXLarge ?? defaultNumberOfItems);

  return (
    <>
      {heading && <Heading type="h2">{heading}</Heading>}

      {text && (
        <>
          <Text value={text} size="large" />
          <HorizontalSpacer size="medium" />
        </>
      )}

      <Splide
        aria-label="Slider"
        className={classNames("swiper", fullWidth && "swiper--full-width", isSidebar && "swiper--sidebar")}
        hasTrack={false}
        options={{
          arrows: showArrowsAlways ? showArrowsAlways : isSidebar ? false : items.length > defaultNumberOfItems,
          breakpoints: {
            // Breakpoints her må match dem i variables.module.scss > NB: media-query her er max-width, og ikke min-width, som vi har ellers
            // Her må dem ligge i rekkefølge størst til minst
            1280: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: isSidebar ? items.length > 1 : items.length > defaultNumberOfItems,
              perPage: itemsPerPageXLargeScreen
            },
            1024: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: isSidebar ? items.length > 1 : items.length > defaultNumberOfItems,
              perPage: itemsPerPageLargeScreen
            },
            768: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: items.length > 2,
              perPage: itemsPerPageMediumScreen
            },
            600: {
              arrows: showArrowsAlways ? showArrowsAlways : false,
              pagination: items.length > 1,
              perPage: itemsPerPageSmallScreen
            }
          },
          easing: "linear",
          gap: "2.4rem", // tilsvarer $left-and-right-inset
          lazyLoad: "nearby",
          pagination: isSidebar ? true : false,
          paginationKeyboard: true,
          perPage: itemsPerPageXLargeScreen,
          role: "",
          type: notInfinite ? "slide" : "loop",
          width: "100%",
          flickMaxPages: 1,
          perMove: 1
        }}
        role=""
        tag="section"
      >
        <div className="splide__arrows">
          <button
            aria-label="Scroll til venstre"
            className={classNames(
              "splide__arrow splide__arrow--prev swiper__arrow-button swiper__arrow-button--prev",
              showArrowsOnTop && "swiper__arrow-button--on-top"
            )}
          >
            <ArrowRightIcon size="large" />
          </button>

          <button
            aria-label="Scroll til høyre"
            className={classNames(
              "splide__arrow splide__arrow--next swiper__arrow-button swiper__arrow-button--next",
              showArrowsOnTop && "swiper__arrow-button--on-top"
            )}
          >
            <ArrowRightIcon size="large" />
          </button>
        </div>

        <SplideTrack>
          {items.map((item, index) => {
            return <SplideSlide key={index}>{item}</SplideSlide>;
          })}
        </SplideTrack>
      </Splide>

      {!isSidebar && <HorizontalSpacer size="large" />}
    </>
  );
};
