import { BookableEventPartsFragment } from "@/generated/client.generated";
import { Link } from "../base/link";
import { VerticalContainer } from "../layout/vertical-container";
import { HorizontalContainer } from "../layout/horizontal-container";
import { Text } from "./text";
import dayjs from "dayjs";
require("dayjs/locale/nb");
dayjs.locale("nb");
import { Button } from "../base/button";
import { Image } from "./image";
import { IconGroupLocations } from "../icons/icon-group-locations";
import { paths } from "@/utils/paths-utils";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { CheckIcon } from "../icons/icons";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Heading } from "../base/heading";
import { capitalizeWord } from "@/utils/string-utils";

interface EventCardProps {
  parts: BookableEventPartsFragment;
  status: "Booked" | "Bookable" | "Attended";
}

export const EventCard = ({ parts, status = "Bookable" }: EventCardProps) => {
  const times: Array<string> = parts.notification?.split("\n") ?? [];

  const eventStart = dayjs(parts.duration.start);
  const eventEnd = dayjs(parts.duration.end);
  return (
    <div className="event-card">
      <Link
        href={paths.event(parts.event.slug, { bookingId: parts.id, centerId: parts.center.id })}
        textDecoration="none"
      >
        <div className="event-card__media">
          {parts.event.page.thumbnailImage && (
            <Image
              alt={parts.event.page.thumbnailImage.alternativeText ?? parts.event.page.title}
              src={parts.event.page.thumbnailImage.url}
              placeholder={parts.event.page.thumbnailImage.placeholder ?? undefined}
            />
          )}
        </div>

        <div className="event-card__content">
          <VerticalContainer height="full" horizontalPlacement="left" verticalPlacement="space" width="full">
            <VerticalContainer height="full" width="full">
              <HorizontalContainer width="full" horizontalPlacement="space" verticalPlacement="center">
                <VerticalContainer>
                  <Text
                    size="small"
                    value={
                      eventStart.format("DD.MM") === eventEnd.format("DD.MM")
                        ? capitalizeWord(eventStart.format("dddd DD.MM"))
                        : `${eventStart.format("DD.MM")} - ${eventEnd.format("DD.MM")}`
                    }
                  />
                  <HorizontalSpacer size="x-small" />
                </VerticalContainer>

                <IconGroupLocations iconLabels={[parts.center.page.title]} textSize="small" />
              </HorizontalContainer>

              <Heading type="h2">{parts.event.page.title}</Heading>

              {times.length > 0 && (
                <div className="event-card__content__times event-card__content__times--gradient">
                  {times.map((time, index) => (
                    <Text value={time} size="small" key={index} />
                  ))}
                </div>
              )}
            </VerticalContainer>

            {status === "Bookable" && <Button buttonType={"secondary"}>Les mer og meld deg på</Button>}

            {status === "Booked" && (
              <HorizontalContainer verticalPlacement="center">
                <Text value="Du er påmeldt!" weight="bold" />
                <VerticalSpacer size="x-small" />
                <Text value="Velkommen!" />
              </HorizontalContainer>
            )}

            {status === "Attended" && (
              <Button buttonType={"primary"} disabled>
                <HorizontalContainer verticalPlacement="center" noWrap>
                  <Text value="Deltok" weight="bold" />
                  <VerticalSpacer size="x-small" />
                  <CheckIcon size="small" />
                </HorizontalContainer>
              </Button>
            )}
          </VerticalContainer>
        </div>
      </Link>
    </div>
  );
};
