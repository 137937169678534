import ContentLoader from "react-content-loader";

interface TextSkeletonProps {
  size?: "x-small" | "small" | "normal" | "large" | "x-large";
}
export const TextSkeleton = ({ size }: TextSkeletonProps) => {
  if (size === "x-small") {
    return (
      <ContentLoader viewBox="0 0 60 21" height={21} width={60}>
        <rect width="60" height="21" />
      </ContentLoader>
    );
  } else if (size === "small") {
    return (
      <ContentLoader viewBox="0 0 123 21" height={21} width={123}>
        <rect width="123" height="21" />
      </ContentLoader>
    );
  } else if (size === "large") {
    return (
      <ContentLoader height={52} width={200}>
        <rect height="52" width="100%" />
      </ContentLoader>
    );
  } else {
    return (
      <ContentLoader
        speed={2}
        width={360}
        height={84}
        viewBox="0 0 340 84"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
        <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
        <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
        <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
        <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
        <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
        <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
        <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
      </ContentLoader>
    );
  }
};
