import { FilterInput } from "@/generated/client.generated";
import { Suspense } from "react";
import { VerticalContainer } from "../../layout/vertical-container";
import { Heading } from "@/components/base/heading";
import { HorizontalSpacer } from "@/components/layout/horizontal-spacer";
import { FullWidthContainer } from "@/components/layout/full-width-container";
import { LinkButton } from "@/components/ui/link-button";
import { Text } from "@/components/ui/text";
import { HorizontalContainer } from "@/components/layout/horizontal-container";
import { BookingNextEventsResultsSwiper } from "./booking-next-events-results-swiper";
import { PtFinderSkeleton } from "@/components/skeletons/pt-finder-skeleton";

interface BookingNextEventsForFiltersSwiperProps {
  currentFilters: ReadonlyArray<FilterInput>;
  seeMoreOfThis?: {
    url: string;
    text: string;
  };
  take: number;
  title: string;
  isSidebar?: boolean;
}

export function BookingNextEventsForFiltersSwiper({
  currentFilters,
  take,
  title,
  seeMoreOfThis,
  isSidebar
}: BookingNextEventsForFiltersSwiperProps) {
  return (
    <FullWidthContainer paddingOnMobile>
      <HorizontalContainer horizontalPlacement="center" noWrap width="full">
        <VerticalContainer width="full" horizontalPlacement="center">
          <Heading type="h2">{title}</Heading>
          <HorizontalSpacer size="medium" />

          <Suspense fallback={<PtFinderSkeleton />}>
            <BookingNextEventsResultsSwiper currentFilters={currentFilters} take={take} isSidebar={isSidebar} />
          </Suspense>

          {seeMoreOfThis && (
            <LinkButton buttonType="secondary" href={seeMoreOfThis.url}>
              <Text value={seeMoreOfThis.text} />
            </LinkButton>
          )}
        </VerticalContainer>
      </HorizontalContainer>
    </FullWidthContainer>
  );
}
