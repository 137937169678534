"use client";
import {
  BookableActivitiesDocument,
  BookableActivitiesQuery,
  BookingButtonPartsFragment,
  FilterInput,
  FilterTypes
} from "@/generated/client.generated";
import dayjs from "dayjs";
import { Text } from "@/components/ui/text";
import React, { useContext } from "react";
import { UserContext } from "@/contexts/user-context";
import { HorizontalSpacer } from "@/components/layout/horizontal-spacer";
import { BookingButtonWrapper } from "../booking-button/booking-button-wrapper";
import { BookingType } from "../types";
import { TextTheme } from "@/components/ui/ui.model";
import { useSuspenseQuery } from "@apollo/client";

interface BookingNextActivitiesResultsProps {
  currentFilters: ReadonlyArray<FilterInput>;
  showFullDate: boolean;
  take: number;
  textTheme: TextTheme;
  activityStartFilter?: string;
  activityEndFilter?: string;
}

export function BookingNextActivitiesResults({
  currentFilters,
  showFullDate,
  take,
  textTheme,
  activityStartFilter,
  activityEndFilter
}: BookingNextActivitiesResultsProps) {
  // get bookable activities
  // Setter datofilter til activityStart-dato hvis activityStartFilter er satt

  const filters = currentFilters.filter((f) => f.type !== FilterTypes.ToDate && f.type !== FilterTypes.FromDate);
  const toDate = currentFilters.find((f) => f.type === FilterTypes.ToDate);
  const fromDate = currentFilters.find((f) => f.type === FilterTypes.FromDate);

  if (!showFullDate) {
    if (activityStartFilter && toDate && toDate.value === dayjs().format("YYYY-MM-DD")) {
      filters.push({ type: FilterTypes.ToDate, value: activityStartFilter });
      filters.push({ type: FilterTypes.FromDate, value: activityStartFilter });
    } else {
      if (toDate) {
        filters.push({ type: FilterTypes.ToDate, value: toDate.value });
        filters.push({ type: FilterTypes.FromDate, value: toDate.value });
      }
    }
  } else {
    if (activityStartFilter && activityEndFilter) {
      filters.push({ type: FilterTypes.ToDate, value: activityEndFilter });
      filters.push({ type: FilterTypes.FromDate, value: activityStartFilter });
    } else {
      if (toDate && fromDate) {
        filters.push(toDate);
        filters.push(fromDate);
      }
    }
  }

  const { data, error } = useSuspenseQuery<BookableActivitiesQuery>(BookableActivitiesDocument, {
    variables: {
      filter: filters,
      skip: 0,
      take: take
    }
  });

  if (error) {
    throw error;
  }
  const { myBookedActivities } = useContext(UserContext);

  const bookableActivities = data.BookableGroupActivities.map((activity: BookingButtonPartsFragment) => {
    return {
      ...activity,
      instructors: [...activity.instructors]
    } as BookingButtonPartsFragment;
  }).sort((a: BookingButtonPartsFragment, b: BookingButtonPartsFragment) =>
    dayjs(a.start).isBefore(dayjs(b.start)) ? -1 : 1
  ) as ReadonlyArray<BookingButtonPartsFragment>;

  if (bookableActivities.length === 0) {
    return <Text align="center" style="italic" value="Ingen kommende timer" color={textTheme} />;
  }

  return (
    <>
      {bookableActivities.map((activity) => (
        <React.Fragment key={activity.id}>
          <BookingButtonWrapper
            parts={activity}
            bookingData={
              myBookedActivities
                ? myBookedActivities.find((bookedActivity) => bookedActivity.BookableGroupActivity.id === activity.id)
                : undefined
            }
            bookingType={BookingType.GRUPPETIME}
            showFullDate={showFullDate}
            isCancelled={false}
          />
          <HorizontalSpacer />
        </React.Fragment>
      ))}
    </>
  );
}
