import {
  SubscriptionCardFilterPartsFragment,
  FilterTypes,
  SubscriptionPartsFragment,
  GetSubscriptionsFilteredQuery,
  GetSubscriptionsFilteredDocument
} from "@/generated/client.generated";

import { useSuspenseQuery } from "@apollo/client";
import { MultipleSubscriptionCards } from "./multiple-subscription-cards";

interface SubscriptionCardFilterProps {
  isSidebar?: boolean;
  filterValues?: SubscriptionCardFilterPartsFragment;
}

export const SubscriptionCardFilter = ({ isSidebar = false, filterValues }: SubscriptionCardFilterProps) => {
  const currentFilters = [
    filterValues?.centerRef?.id && { type: FilterTypes.Center, value: filterValues.centerRef.id.toString() },
    filterValues?.coverage && { type: FilterTypes.Coverage, value: filterValues.coverage },
    filterValues?.customerType?.id && {
      type: FilterTypes.CustomerType,
      value: filterValues.customerType.id.toString()
    },
    filterValues?.recurrenceType && {
      type: FilterTypes.Recurrence,
      value: filterValues.recurrenceType
    }
  ].filter(Boolean); // Filter out values that are null or undefined from the current filters, as the query consuming it as a variable does not like undefined or null values. Necessary when querying without a filter

  const subscriptionCards: ReadonlyArray<SubscriptionPartsFragment> =
    useSuspenseQuery<GetSubscriptionsFilteredQuery>(GetSubscriptionsFilteredDocument, {
      variables: {
        filter: currentFilters
      }
    })?.data?.Subscriptions ?? [];

  return (
    <MultipleSubscriptionCards
      isSidebar={isSidebar}
      subscriptionCards={subscriptionCards}
      centerId={filterValues?.centerRef?.id}
    />
  );
};
