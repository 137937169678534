import { PtDurationType } from "@/generated/client.generated";

export const ptDurationTypeToNumber = (ptDuration: PtDurationType) => {
  switch (ptDuration) {
    case PtDurationType.Pt25:
      return 25;
    case PtDurationType.Pt50:
      return 50;
    default: {
      throw "Unsupported PTDuration";
    }
  }
};

export const roleToHumanString = (role: string) => {
  switch (role.toLocaleLowerCase()) {
    case "pt1":
      return "PT-nivå 1";
    case "pt2":
      return "PT-nivå 2";
    case "pt3":
      return "PT-nivå 3";
    case "physiotherapist":
      return "Fysioterapeut";
    case "kiropraktor":
      return "Kiropraktor";
    case "naprapat":
      return "Naprapat";
    case "coach":
      return "Trener";
    case "Instructor":
      return "Instruktør";
    default: {
      throw "";
    }
  }
};
