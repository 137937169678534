import { FilterInput } from "@/generated/client.generated";
import { Suspense } from "react";
import { VerticalContainer } from "../../layout/vertical-container";
import { BookingNextEventsResults } from "./booking-next-events-results";
import { Heading } from "@/components/base/heading";
import { HorizontalSpacer } from "@/components/layout/horizontal-spacer";
import { FullWidthContainer } from "@/components/layout/full-width-container";
import { LinkButton } from "@/components/ui/link-button";
import { Text } from "@/components/ui/text";
import { TextTheme } from "@/components/ui/ui.model";
import { HorizontalContainer } from "@/components/layout/horizontal-container";
import { ContentContainer } from "@/components/layout/content-container";
import { BookingButtonSkeletons } from "@/components/skeletons/booking-button-skeletons";

interface BookingNextEventsForFiltersProps {
  currentFilters: ReadonlyArray<FilterInput>;
  seeMoreOfThis?: {
    url: string;
    text: string;
  };
  take: number;
  title: string;
  backgroundColor?: string;
  textTheme?: string;
  defaultBgColorDark?: boolean;
}

export function BookingNextEventsForFilters({
  currentFilters,
  take,
  title,
  seeMoreOfThis,
  backgroundColor,
  textTheme,
  defaultBgColorDark
}: BookingNextEventsForFiltersProps) {
  const derivedTextTheme: TextTheme = textTheme
    ? textTheme === "dark"
      ? "dark"
      : "light"
    : defaultBgColorDark
      ? "light"
      : "dark";

  return (
    <FullWidthContainer backgroundColor={backgroundColor} defaultBgColorDark={defaultBgColorDark} paddingOnMobile>
      <ContentContainer width="x-large">
        <HorizontalContainer horizontalPlacement="center" width="full">
          <VerticalContainer width="full" horizontalPlacement="center">
            <Heading type="h2" color={derivedTextTheme}>
              {title}
            </Heading>
            <HorizontalSpacer size="medium" />

            <Suspense fallback={<BookingButtonSkeletons number={take} />}>
              <BookingNextEventsResults currentFilters={currentFilters} take={take} textTheme={derivedTextTheme} />
            </Suspense>
            <HorizontalSpacer size="large" />

            {seeMoreOfThis && (
              <LinkButton buttonType="secondary" href={seeMoreOfThis.url}>
                <Text value={seeMoreOfThis.text} />
              </LinkButton>
            )}
          </VerticalContainer>
        </HorizontalContainer>
      </ContentContainer>
    </FullWidthContainer>
  );
}
