import { ReactNode, useState } from "react";

import { classNames } from "@/utils/styling-utils";
import { ChevronUpIcon, ChevronDownIcon } from "../icons/icons";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { useScrollToElement } from "@/hooks/use-scroll-to-element";
import { HorizontalContainer } from "../layout/horizontal-container";
import { VerticalSpacer } from "../layout/vertical-spacer";

export interface DetailsAllProps {
  fullWidth?: boolean;

  /**
   * Set id if you want to use anchor link with scroll-to
   */
  id?: string;
}

interface DetailsProps extends DetailsAllProps {
  children: ReactNode;
  open?: boolean;
  summary: string;
  summaryExtra?: string;
  summaryPrefix?: string;
}

export const Details = ({ children, fullWidth, id, open, summary, summaryExtra, summaryPrefix }: DetailsProps) => {
  const [summaryIsOpen, setSummaryIsOpen] = useState<boolean>(!!open);
  const [detailsIsOpen, setDetailsIsOpen] = useState<boolean>(!!open);

  const scrollTo = useScrollToElement(id, () => {
    // åpne komponenten når vi scroller til den
    setSummaryIsOpen(true);
    setDetailsIsOpen(true);
  });

  return (
    <DetailsWrapper fullWidth={fullWidth}>
      <HorizontalSpacer size="x-small" />

      <details className="details" id={scrollTo ?? ""} open={detailsIsOpen}>
        <summary onClick={() => setSummaryIsOpen(!summaryIsOpen)}>
          <div
            className={classNames(
              "details__summary-container",
              summaryPrefix && "details__summary-container--has-prefix"
            )}
          >
            {summaryPrefix && <div className="details__summary-container__prefix">{summaryPrefix}</div>}
            <div className="details__summary-container__content">
              <HorizontalContainer noWrap>
                {summary} <VerticalSpacer size={summaryExtra ? "x-large" : "small"} />
              </HorizontalContainer>
              <HorizontalContainer noWrap>
                {summaryExtra} <VerticalSpacer />
              </HorizontalContainer>
            </div>
          </div>
          {summaryIsOpen ? <ChevronUpIcon clickThrough /> : <ChevronDownIcon clickThrough />}
        </summary>
        <HorizontalSpacer />

        <div
          className={classNames(
            "details__children-container",
            summaryPrefix && "details__children-container--has-prefix"
          )}
        >
          {children}
        </div>
        <HorizontalSpacer size="x-small" />
      </details>

      <HorizontalSpacer size="x-small" />
    </DetailsWrapper>
  );
};

// denne må ligge rundt komponenten i blocks
interface DetailsAreaProps {
  children: ReactNode;
}

export const DetailsArea = ({ children }: DetailsAreaProps) => <div className="details-area">{children}</div>;

interface DetailsWrapperProps extends DetailsAllProps {
  children: ReactNode;
  hoverBgColor?: boolean;
}

export const DetailsWrapper = ({ children, fullWidth, hoverBgColor, id }: DetailsWrapperProps) => (
  <div
    className={classNames(
      "details-wrapper",
      fullWidth && "details-wrapper--full-width",
      hoverBgColor && "details-wrapper--hover-bg-color"
    )}
    id={id}
  >
    {children}
  </div>
);
