import React from "react";
import { TrainerCard } from "./trainer-card";
import { Text } from "../ui/text";
import { PtPartsFragment } from "@/generated/client.generated";

interface TrainerGridProps {
  fallbackText: string;
  instructors: ReadonlyArray<PtPartsFragment>;
}

export const TrainerGrid = ({ instructors, fallbackText }: TrainerGridProps) => {
  const trainerCards = instructors.map((pt) => {
    return <TrainerCard key={pt.id} parts={pt} onlyDisplayTheseRoles={["pt1", "pt2", "pt3"]} />;
  });

  if (instructors.length === 1) {
    return (
      <div className={"trainer-grid trainer-grid__single"}>
        {instructors.length > 0 ? trainerCards : <Text size="small" style="italic" value={fallbackText} />}
      </div>
    );
  }

  if (instructors.length === 2) {
    return (
      <div className={"trainer-grid trainer-grid__side-by-side"}>
        {instructors.length > 0 ? trainerCards : <Text size="small" style="italic" value={fallbackText} />}
      </div>
    );
  }

  return (
    <div className="trainer-grid">
      {instructors.length > 0 ? trainerCards : <Text size="small" style="italic" value={fallbackText} />}
    </div>
  );
};
