"use client";
import { BlockUnionPartsFragment } from "@/generated/client.generated";
import { Block } from "./block";

interface DynamicZoneProps {
  blocks: ReadonlyArray<BlockUnionPartsFragment>;
  isSidebar?: boolean;
}

export function DynamicZone({ blocks, isSidebar }: DynamicZoneProps) {
  if (blocks.length === 0) {
    return <></>;
  }

  return (
    <>
      {blocks.map((blockParts, index) => {
        return <Block key={index} isSidebar={isSidebar} parts={blockParts} />;
      })}
    </>
  );
}
