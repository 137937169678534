import { ChevronRightIcon } from "../icons/icons";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { useScrollToElement } from "@/hooks/use-scroll-to-element";
import { HorizontalContainer } from "../layout/horizontal-container";
import { Link } from "../base/link";
import { Text } from "./text";
import { DetailsAllProps, DetailsWrapper } from "./details";

interface DetailsLookalikeLinkProps extends DetailsAllProps {
  openUrlInNewTab?: boolean;
  text: string;
  url: string;
}

export const DetailsLookalikeLink = ({ fullWidth, id, openUrlInNewTab, text, url }: DetailsLookalikeLinkProps) => {
  const scrollTo = useScrollToElement(id);

  return (
    <DetailsWrapper fullWidth={fullWidth} hoverBgColor id={scrollTo ?? ""}>
      <Link href={url} newTab={openUrlInNewTab} textDecoration="none">
        <HorizontalSpacer />

        <HorizontalContainer horizontalPlacement="space" noWrap verticalPlacement="center" width="full">
          <Text value={text} weight="bold" />
          <ChevronRightIcon clickThrough />
        </HorizontalContainer>

        <HorizontalSpacer />
      </Link>
    </DetailsWrapper>
  );
};
