import { ReactNode } from "react";

import { MediaPartsFragment, ResponsiveMediaPartsFragment } from "@/generated/client.generated";
import { classNames } from "@/utils/styling-utils";
import { Media } from "./media";
import { Heading } from "../base/heading";
import { TextTheme } from "./ui.model";
import { useScrollToElement } from "@/hooks/use-scroll-to-element";
import { Text } from "./text";

export type ImageHeadingAndTextCardTextLayout = "normal" | "quote";
export type ImageHeadingAndTextCardTextSize = "normal" | "large";
export type ImageHeadingAndTextCardHorizontalAlignment = "left" | "center";

interface ImageHeadingAndTextCardAllProps {
  heading?: string;
  text?: string;
  url?: string;
}

interface ImageHeadingAndTextCardProps extends ImageHeadingAndTextCardAllProps {
  alignContentHorizontally?: ImageHeadingAndTextCardHorizontalAlignment;
  blockMargin?: boolean;
  fallbackAlt: string; // This alt text will only be used if the image from Strapi lacks an alternative text. The fallback will give context of the image
  fullWidth?: boolean;
  id?: string;
  isSlider?: boolean;
  isSliderFullWidth?: boolean;
  media: MediaPartsFragment | ResponsiveMediaPartsFragment;
  priority?: boolean; // specific for Image
  textLayout?: ImageHeadingAndTextCardTextLayout;
  textSize?: ImageHeadingAndTextCardTextSize;
  textTheme?: TextTheme; // light er default
}

// NB: Hvis denne komponenten får richtext (som FlexCard) med mulighet til å sette inn links,
// må wrapperen ImageHeadingAndTextCardWrapper fjernes
export const ImageHeadingAndTextCard = ({
  alignContentHorizontally,
  blockMargin,
  fallbackAlt,
  fullWidth,
  heading,
  id,
  isSlider,
  isSliderFullWidth,
  media,
  priority,
  text,
  textLayout,
  textSize,
  textTheme,
  url
}: ImageHeadingAndTextCardProps) => {
  const scrollTo = useScrollToElement(id);

  return (
    <div
      className={classNames(
        "image-heading-and-text-card",
        blockMargin && "image-heading-and-text-card--block-margin",
        fullWidth && "image-heading-and-text-card--full-width",
        isSlider && "image-heading-and-text-card--slider",
        isSliderFullWidth && "image-heading-and-text-card--slider--full-width"
      )}
      id={scrollTo ?? ""}
    >
      <ImageHeadingAndTextCardWrapper heading={heading} text={text} url={url}>
        <Media fallbackAlt={fallbackAlt} media={media} priority={priority} />

        <div
          className={classNames(
            "image-heading-and-text-card__content",
            alignContentHorizontally &&
              `image-heading-and-text-card__content--horizontal-align--${alignContentHorizontally}`,
            textTheme && `image-heading-and-text-card__content--text-theme--${textTheme}`
          )}
        >
          {heading && <Heading type="h2">{heading}</Heading>}

          {text && (
            <div
              className={classNames(
                "image-heading-and-text-card__content__text",
                alignContentHorizontally &&
                  `image-heading-and-text-card__content__text--horizontal-align--${alignContentHorizontally}`,
                textSize && `image-heading-and-text-card__content__text--text-size--${textSize}`,
                textLayout === "quote" &&
                  `image-heading-and-text-card__content__text--quote${
                    textTheme && ` image-heading-and-text-card__content__text--quote--text-theme--${textTheme}`
                  }`
              )}
            >
              <Text value={text} />
            </div>
          )}
        </div>
      </ImageHeadingAndTextCardWrapper>
    </div>
  );
};

interface ImageHeadingAndTextCardWrapperProps extends ImageHeadingAndTextCardAllProps {
  children: ReactNode;
}

const ImageHeadingAndTextCardWrapper = ({ children, heading, text, url }: ImageHeadingAndTextCardWrapperProps) => {
  return url ? (
    <a
      className="image-heading-and-text-card-wrapper"
      data-component="MediaMedTekst"
      data-label={(heading ?? text) ? `${text?.substring(0, 50)}...` : ""}
      href={url}
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};
