"use client";
import { useEffect, useState } from "react";
import { equals } from "rambda";
import { FilterInput, FilterTypes } from "@/generated/client.generated";
import { FilterButtonContainer } from "./filter-button-container";
import { FilterButtonSmall, FilterButtonSmallSkeleton } from "./filter-button-small";
import { VerticalContainer } from "../layout/vertical-container";
import { Text } from "../ui/text";
import { FilterTheme } from "@/utils/filter-utils";
import dayjs from "dayjs";

require("dayjs/locale/nb");
dayjs.locale("nb");

interface FilterWeekdaysProps {
  currentFilters: ReadonlyArray<FilterInput>;
  onClickHandler: { onClick: (date: string) => void };
  padding?: boolean;
  theme?: FilterTheme;
  activityStartFilter?: string;
  activityEndFilter?: string;
}

export const FilterWeekdays = ({
  currentFilters,
  onClickHandler,
  padding,
  theme,
  activityStartFilter,
  activityEndFilter
}: FilterWeekdaysProps) => {
  const startDate = activityStartFilter ? dayjs(activityStartFilter) : dayjs();
  const numberOfDays = activityStartFilter
    ? dayjs(activityEndFilter).diff(dayjs(activityStartFilter), "day", true) + 1
    : 8;

  const todayFormatted = startDate.format("YYYY-MM-DD");
  const { onClick } = onClickHandler;

  const [selectedDate, setSelectedDate] = useState<string>(
    dayjs(currentFilters.find((f) => equals(f.type, FilterTypes.FromDate))?.value ?? startDate).format("YYYY-MM-DD")
  );

  useEffect(() => {
    const fromDate = currentFilters.find((f) => equals(f.type, FilterTypes.FromDate));
    if (fromDate) {
      setSelectedDate(dayjs(fromDate.value).format("YYYY-MM-DD"));
    }
  }, [currentFilters]);

  // Skriver ut hele dagsnavnet når antall dager er mindre enn 5, eks. mandag -> ma
  const dayOfWeek = numberOfDays < 5 ? startDate.format("dddd") : startDate.format("dd");
  const dateOfWeek = startDate.format("DD");

  return (
    <FilterButtonContainer padding={padding}>
      {/* today's date */}
      <FilterButtonSmall
        onClick={() => {
          setSelectedDate(todayFormatted);
          onClick(todayFormatted);
        }}
        selected={todayFormatted === selectedDate || selectedDate === ""}
        theme={theme}
        width={100 / numberOfDays}
      >
        <VerticalContainer horizontalPlacement="center">
          <Text size="large" value={dateOfWeek} />
          <Text capitalize size="small" value={dayOfWeek} />
        </VerticalContainer>
      </FilterButtonSmall>

      {/* the next 6 days' date */}
      {Array.from({ length: numberOfDays - 1 }, (_, i) => {
        const currentDate = startDate.add(i + 1, "day");
        const currentDateFormatted = currentDate.format("YYYY-MM-DD");

        // Skriver ut hele dagsnavnet når antall dager er mindre enn 5, eks. ma -> mandag
        const currentDayOfWeek = numberOfDays < 5 ? currentDate.format("dddd") : currentDate.format("dd");
        const currentDateOfWeek = currentDate.format("DD");

        return (
          // setter bredden på knappene til å være lik antall dager
          <FilterButtonSmall
            key={i}
            onClick={() => {
              setSelectedDate(currentDateFormatted);
              onClick(currentDateFormatted);
            }}
            selected={currentDateFormatted === selectedDate}
            theme={theme}
            width={100 / numberOfDays}
          >
            <VerticalContainer horizontalPlacement="center">
              <Text size="large" value={currentDateOfWeek} />
              <Text capitalize size="small" value={currentDayOfWeek} />
            </VerticalContainer>
          </FilterButtonSmall>
        );
      })}
    </FilterButtonContainer>
  );
};

export const FilterWeekdaysSkeleton = () => {
  return (
    <FilterButtonContainer padding={true}>
      <FilterButtonSmallSkeleton />
    </FilterButtonContainer>
  );
};
