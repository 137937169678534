import { IntervalPaymentType, RecurrenceType, SubscriptionPartsFragment } from "@/generated/client.generated";
import { RegisterButton } from "./register-button";
import { paths } from "@/utils/paths-utils";
import { FullWidthContainer } from "../layout/full-width-container";
import { HorizontalContainer } from "../layout/horizontal-container";
import { Swiper } from "./swiper";
import { selectItemEvent } from "@/analytics/event-logger";

interface MultipleSubscriptionCardsProps {
  isSidebar?: boolean;
  subscriptionCards?: ReadonlyArray<SubscriptionPartsFragment>;
  centerId?: number | undefined;
}

export const MultipleSubscriptionCards = ({
  isSidebar = false,
  subscriptionCards,
  centerId
}: MultipleSubscriptionCardsProps) => {
  if (!subscriptionCards || subscriptionCards.length === 0) {
    return <></>;
  }

  const displayAsSlider = subscriptionCards.length > 2;

  const subscriptionCardItems = subscriptionCards.map((item, index) => {
    return (
      <RegisterButton
        buttonText={"Bli 3T-medlem nå"}
        blockMargin={subscriptionCards.length === 2}
        fixedContentHeight
        ingress={item.ingress ?? undefined}
        isCampaign={item.isCampaign ?? undefined}
        isSlider={displayAsSlider}
        key={index}
        length={subscriptionCards.length}
        notification={item.notification ?? undefined}
        onClick={() => selectItemEvent("medlemskapslider", "medlemskapslider", [subscriptionCardToGAItem(item, index)])}
        priceAmount={item.price.amount}
        priceInterval={item.intervalPayment === IntervalPaymentType.Month ? "1, MONTH" : ""}
        sellingPoints={item.membershipSummary ?? undefined}
        sellingPointsHidden={item.membershipSummaryHidden ?? undefined}
        title={item.name ?? ""}
        url={paths.bliMedlemPrefilled(item?.customerTypeRefs?.[0]?.id, centerId?.toString(), item.id)}
      />
    );
  });

  // Vises under hverandre på mobil/ved siden av hverandre på desktop hvis det er akkurat 2 elementer
  if (subscriptionCards.length === 2) {
    return (
      <>
        <FullWidthContainer>
          <HorizontalContainer horizontalPlacement="center" verticalPlacement="top" width="full">
            {subscriptionCardItems}
          </HorizontalContainer>
        </FullWidthContainer>
      </>
    );
  }

  return (
    <>
      {/* Viser som swiper hvis det er flere enn 2 items */}
      {displayAsSlider ? (
        <Swiper
          isSidebar={isSidebar}
          items={subscriptionCardItems}
          itemsPerPageMedium={2}
          itemsPerPageLarge={2}
          itemsPerPageXLarge={3}
        />
      ) : (
        subscriptionCardItems
      )}
    </>
  );
};

function subscriptionCardToGAItem(sc: SubscriptionPartsFragment, index: number) {
  return {
    index: index,
    item_brand: "3T",
    item_id: sc.id,
    item_list_id: "medlemskapslider",
    item_list_name: "medlemskapslider",
    item_name: sc.name,
    price: Math.round((sc.recurrenceType === RecurrenceType.Recurring ? sc.price.amount * 12 : sc.price.amount) / 100),
    quantity: 1
  };
}
