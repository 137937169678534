// Need this function for type safety, as the size returned from the api is just a string
export function mapSpacingToHorizontalSpacerProps(size: string | null) {
  switch (size) {
    case "xx-small":
      return "xx-small";
    case "x-small":
      return "x-small";
    case "small":
      return "small";
    case "medium":
      return "medium";
    case "large":
      return "large";
    case "x-large":
      return "x-large";
    case "xx-large":
      return "xx-large";
    case null:
    default:
      return "x-large";
  }
}
