"use client";

import { SyntheticEvent, useState } from "react";
import ContentLoader from "react-content-loader";

import { classNames } from "@/utils/styling-utils";
import { Heading } from "../base/heading";
import { Link } from "../base/link";
import { Button } from "../base/button";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Text } from "../ui/text";
import { Markdown } from "./markdown";
import { LinkButton } from "./link-button";
import { HorizontalContainer } from "../layout/horizontal-container";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Price, PriceIntervalType } from "./price";

interface RegisterButtonProps {
  blockMargin?: boolean;
  buttonText?: string;
  description?: string;
  fixedContentHeight?: boolean;
  ingress?: string;
  isCampaign?: boolean;
  isSlider?: boolean;
  length: number;
  notification?: string;
  onClick?: (e: SyntheticEvent) => void;
  priceAmount?: number;
  priceInterval?: PriceIntervalType;
  selected?: boolean;
  sellingPoints?: string;
  sellingPointsHidden?: string;
  title: string;
  url?: string;
}

export const RegisterButton = ({
  blockMargin,
  buttonText,
  description,
  fixedContentHeight,
  ingress,
  isCampaign,
  isSlider,
  length,
  notification,
  onClick,
  priceAmount,
  priceInterval,
  selected,
  sellingPoints,
  sellingPointsHidden,
  title,
  url
}: RegisterButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const isOddNumber = length % 2 !== 0;
  const isOnlyOne = length === 1;

  return (
    <div
      className={classNames(
        "register-button",
        blockMargin && "register-button--block-margin",
        isOddNumber && "register-button--is-odd-number",
        isOnlyOne && "register-button--is-only-one",
        selected && "register-button--selected", // TODO: mangler styling for selected,
        isSlider && "register-button--slider"
      )}
    >
      {notification ? (
        <div className="register-button__notification">
          <span className="register-button__notification__line"></span>
          <Text color="gray" noWrap size="small" value={notification} />
          <span className="register-button__notification__line"></span>
        </div>
      ) : (
        !isOnlyOne && <div className="register-button__notification__spacer"></div>
      )}

      <div
        className={classNames(
          "register-button__inner",
          fixedContentHeight && `register-button__inner--fixed-content-height${isSlider ? "--slider" : ""}`
        )}
      >
        <div
          className={classNames(
            "register-button__inner__title",
            isCampaign && "register-button__inner__title--campaign"
          )}
        >
          <Heading type="h2">{title}</Heading>

          {isCampaign && <div className="register-button__inner__title__campaign"></div>}
        </div>

        <HorizontalSpacer size="medium" />

        {ingress && (
          <div className="register-button__inner__content">
            <Text align="center" value={ingress} weight="bold" />
            <HorizontalSpacer />
          </div>
        )}

        {description && (
          <div className="register-button__inner__content">
            <Text align="center" value={description} weight="bold" />
            <HorizontalSpacer />
          </div>
        )}

        {priceAmount !== undefined && (
          <>
            <HorizontalSpacer />
            <Price amount={priceAmount} interval={priceInterval} size="large" />
            <HorizontalSpacer size="medium" />
          </>
        )}

        {sellingPoints && (
          <div className={classNames("register-button__inner__info register-button__inner__info--open")}>
            <Markdown horizontalPlacement="center" width="full">
              {sellingPoints}
            </Markdown>
          </div>
        )}

        {sellingPointsHidden && isOpen && (
          <div className={classNames("register-button__inner__info", isOpen && "register-button__inner__info--open")}>
            <Markdown horizontalPlacement="center" width="full">
              {sellingPointsHidden}
            </Markdown>
          </div>
        )}

        {sellingPointsHidden && (
          <>
            <HorizontalSpacer size="x-small" />
            <Link
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? "Se mindre informasjon" : "Se mer informasjon"}
            </Link>
            <HorizontalSpacer size="medium" />
          </>
        )}

        <HorizontalSpacer size="medium" />

        <HorizontalContainer horizontalPlacement="center" noWrap width="full">
          <VerticalSpacer size="large" />

          {url ? (
            <LinkButton buttonType="primary" href={url} size="big" onClick={(e) => onClick && onClick(e)}>
              <Text size="large" value={buttonText ?? "Velg"} weight="black" />
            </LinkButton>
          ) : (
            <Button buttonType="primary" onClick={onClick} size="big">
              <Text size="large" value={buttonText ?? "Velg"} weight="black" />
            </Button>
          )}

          <VerticalSpacer size="large" />
        </HorizontalContainer>

        <HorizontalSpacer size="large" />
      </div>
    </div>
  );
};

interface RegisterButtonSkeletonProps {
  length: number;
  size: "small" | "large";
}

export const RegisterButtonSkeleton = ({ length, size }: RegisterButtonSkeletonProps) => {
  const isOddNumber = length % 2 !== 0;
  const isOnlyOne = length === 1;

  return (
    <div
      className={classNames(
        "register-button",
        isOddNumber && "register-button--is-odd-number",
        isOnlyOne && "register-button--is-only-one"
      )}
    >
      {size === "small" ? (
        <ContentLoader viewBox="0 0 380 65" height={65} width={380}>
          <rect width="380" height="65" rx="4" ry="4" />
        </ContentLoader>
      ) : (
        <ContentLoader viewBox="0 0 380 231" height={231} width={380}>
          <rect width="380" height="231" rx="4" ry="4" />
        </ContentLoader>
      )}
      <HorizontalSpacer size="medium" />
    </div>
  );
};
