import { CheckIconCustomizable } from "../icons/icons";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { VerticalContainer } from "../layout/vertical-container";
import { Text } from "./text";

interface SellingPointsProps {
  checkMarkColor: string;
  sellingPoints: readonly string[];
  checkMarkSize: string;
}
interface SellingPointProps {
  checkMarkColor: string;
  text: string;
  checkMarkSize: string;
}

export function SellingPoints({ checkMarkColor, sellingPoints, checkMarkSize }: SellingPointsProps) {
  return (
    <div className="selling-points-wrapper">
      <HorizontalContainer horizontalPlacement={sellingPoints.length === 1 ? "center" : "left"} width="full">
        {sellingPoints.map((text, index) => (
          <SellingPoint checkMarkColor={checkMarkColor} key={text + index} text={text} checkMarkSize={checkMarkSize} />
        ))}
      </HorizontalContainer>
    </div>
  );
}

function SellingPoint({ checkMarkColor, text, checkMarkSize }: SellingPointProps) {
  return (
    <div className="selling-point">
      <VerticalContainer width="full" horizontalPlacement="space">
        <HorizontalContainer horizontalPlacement="center" width="full">
          <CheckIconCustomizable
            customColorHex={checkMarkColor}
            size={typeSafeIconSizeFromString(checkMarkSize)}
            customStrokeWidth="2"
          />
        </HorizontalContainer>
        <HorizontalSpacer size="x-small" />
        <Text value={text} align="center" weight="bold" />
        <HorizontalSpacer />
        <HorizontalSpacer size="xx-small" />
      </VerticalContainer>
    </div>
  );
}

function typeSafeIconSizeFromString(size: string) {
  switch (size) {
    case "x-small":
      return "x-small";
    case "small":
      return "small";
    case "normal":
      return undefined;
    case "large":
      return "large";
    case "x-large":
      return "x-large";
    case "xx-large":
      return "xx-large";
    default:
      return undefined;
  }
}
