import React, { ReactNode } from "react";
import { HorizontalContainer } from "../layout/horizontal-container";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { Text } from "../ui/text";
import { FormatBaseProps } from "../ui/ui.model";
import { classNames } from "@/utils/styling-utils";

interface IconGroupProps {
  icons: Array<{ icon: ReactNode; text: string }>;
  textSize?: FormatBaseProps["size"];
  noWrap?: boolean;
  hideOverflow?: boolean;
}
export const IconGroup = ({ icons, textSize, noWrap = false, hideOverflow = false }: IconGroupProps) => {
  const horizontalPlacement = icons.length > 2 ? "space" : "center";
  const spacing = icons.length > 2 ? "small" : "medium";

  return (
    <div className={classNames("icon-group", hideOverflow && "icon-group--hide-overflow")}>
      <div
        className={classNames("icon-group__scroll-container", hideOverflow && "icon-group__scroll-container--scroll-x")}
      >
        <HorizontalContainer horizontalPlacement={horizontalPlacement} noWrap={noWrap} width="full">
          {icons.map((icon, index) => {
            return (
              <React.Fragment key={icon.text}>
                <HorizontalContainer noWrap verticalPlacement="center">
                  {icon.icon}
                  <Text size={textSize} value={icon.text} truncate />
                </HorizontalContainer>
                {index !== icons.length - 1 && <VerticalSpacer size={spacing} />}
              </React.Fragment>
            );
          })}
        </HorizontalContainer>
      </div>
    </div>
  );
};
