import { classNames } from "@/utils/styling-utils";
import { Heading } from "../base/heading";
import { HorizontalContainer } from "../layout/horizontal-container";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { HorizontalAlignmentType } from "../layout/layout.model";
import { LinkButton } from "./link-button";
import { Markdown } from "./markdown";
import { TextTheme } from "./ui.model";

interface TextBlockProps {
  alignButtonHorizontally: HorizontalAlignmentType;
  alignTextHorizontally: HorizontalAlignmentType;
  alignTitleHorizontally: HorizontalAlignmentType;
  buttonText?: string;
  buttonUrl?: string;
  text: string;
  textTheme?: TextTheme;
  title?: string;
}

export const TextBlock = ({
  alignButtonHorizontally,
  alignTextHorizontally,
  alignTitleHorizontally,
  buttonText,
  buttonUrl,
  text,
  textTheme,
  title
}: TextBlockProps) => {
  return (
    <div
      className={classNames(
        "text-block",
        alignTextHorizontally && `text-block--align-text-horizontally-${alignTextHorizontally}`
      )}
    >
      {title && (
        <HorizontalContainer horizontalPlacement={alignTitleHorizontally} width="full">
          <Heading type="h2" color={textTheme}>
            {title}
          </Heading>
        </HorizontalContainer>
      )}

      <HorizontalContainer horizontalPlacement={alignTextHorizontally} width="full">
        <Markdown horizontalPlacement={alignTextHorizontally} replaceHeadingLevel1 textTheme={textTheme}>
          {text}
        </Markdown>
      </HorizontalContainer>

      {buttonText && buttonUrl && (
        <>
          <HorizontalSpacer />
          <HorizontalContainer horizontalPlacement={alignButtonHorizontally} width="full">
            <LinkButton buttonType="secondary" href={buttonUrl}>
              {buttonText}
            </LinkButton>
          </HorizontalContainer>
        </>
      )}
    </div>
  );
};
