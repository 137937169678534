import { Heading } from "../base/heading";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Swiper } from "../ui/swiper";
import { FilterInput, FilterTypes, TrenerGalleriDocument, TrenerGalleriQuery } from "@/generated/client.generated";
import { TrainerCard } from "./trainer-card";
import { Text } from "../ui/text";
import { useSuspenseQuery } from "@apollo/client";
import { ContentContainer } from "../layout/content-container";
import { LinkButton } from "../ui/link-button";
import { TrainerGrid } from "./trainer-grid";

interface TrenerGalleriProps {
  filters: ReadonlyArray<FilterInput>;
  heading: string;
  text?: string;
  isSidebar?: boolean;
  seeMoreOfThis?: {
    url: string;
    text: string;
  };
}

export const TrenerGalleri = ({ filters, heading, text, isSidebar, seeMoreOfThis }: TrenerGalleriProps) => {
  // get bookable events
  const { data } = useSuspenseQuery<TrenerGalleriQuery>(TrenerGalleriDocument, {
    variables: {
      filter: [...filters]
    }
  });

  const displayPTsAsSlider = data.Instructors.length > 2;

  const trainers = data.Instructors.map((trener, index) => (
    <TrainerCard
      isSlider={displayPTsAsSlider}
      parts={trener}
      key={index}
      onlyDisplayTheseRoles={filters.filter((filter) => filter.type === FilterTypes.Role).map((filter) => filter.value)}
    />
  ));
  if (trainers.length === 0) {
    return <></>;
  }
  return (
    <ContentContainer horizontalPlacement="center" width="x-large">
      <Heading type="h2">{heading}</Heading>
      {text && <Text align="center" value={text} />}
      <HorizontalSpacer size="large" />

      {/* Vises under hverandre på mobil/ved siden av hverandre på desktop hvis det er akkurat 2 elementer */}
      {trainers.length < 3 ? (
        <TrainerGrid instructors={data.Instructors} fallbackText="" />
      ) : // Viser som swiper hvis det er flere enn 2 items
      displayPTsAsSlider ? (
        <Swiper
          items={trainers}
          itemsPerPageMedium={2}
          itemsPerPageLarge={2}
          itemsPerPageXLarge={3}
          isSidebar={isSidebar}
        />
      ) : (
        trainers
      )}

      {seeMoreOfThis && (
        <LinkButton buttonType="secondary" href={seeMoreOfThis.url}>
          <Text value={seeMoreOfThis.text} />
        </LinkButton>
      )}
    </ContentContainer>
  );
};
