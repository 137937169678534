import { classNames } from "@/utils/styling-utils";
import { ReactNode } from "react";

interface MoveUpOrDownContainerProps {
  children: ReactNode;
  /**
   * Moves item half a touch-target-size down. Absolute positioning.
   * _Use only if you know what you're doing_
   */
  moveDown?: boolean;
  /**
   * Moves item half a touch-target-size up. Absolute positioning.
   * _Use only if you know what you're doing_
   */
  moveUp?: boolean;
}

export const MoveUpOrDownContainer = ({ children, moveDown, moveUp }: MoveUpOrDownContainerProps) => (
  <div
    className={classNames(
      "move-up-or-down-container",
      moveDown && "move-up-or-down-container--move-down",
      moveUp && "move-up-or-down-container--move-up"
    )}
  >
    {children}
  </div>
);
