import React from "react";

import { NoticeType, NoticeWithMessagePartsFragment } from "@/generated/client.generated";
import { classNames } from "@/utils/styling-utils";
import { Text } from "./text";
import { CheckCircleIcon, ExclamationIcon } from "../icons/icons";
import RichTextJsonRenderer from "@/components/ui/rich-text-json-renderer";
import { useIsDesktop } from "@/hooks/use-is-desktop";

interface NoticeWithMessageProps {
  parts: NoticeWithMessagePartsFragment;
}

function getIconType(noticeType: NoticeType) {
  switch (noticeType) {
    case NoticeType.Error:
    case NoticeType.Warning:
      return <ExclamationIcon size="large" />;
    case NoticeType.Success:
      return <CheckCircleIcon size="large" color={"green"} />;
    default:
      return <CheckCircleIcon size="large" color={"blue"} />;
  }
}

export const NoticeWithMessage = ({ parts }: NoticeWithMessageProps) => {
  const isDesktop = useIsDesktop();

  return (
    <div className={classNames("notice notice--" + parts.notice.toLowerCase())}>
      <div className="notice__content">
        {getIconType(parts.notice)}
        <div>
          {parts.messageRichText ? (
            <RichTextJsonRenderer
              alignTextHorizontally={isDesktop ? "left" : "center"}
              textTheme="dark"
              content={JSON.parse(parts.messageRichText)}
            />
          ) : (
            parts.nullableMessage && (
              <Text value={parts.nullableMessage ?? ""} weight="bold" align={isDesktop ? undefined : "center"} />
            )
          )}
        </div>
      </div>
    </div>
  );
};
